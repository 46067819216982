import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import ProductLayout from "../../components/Layout/productLayout";
import BlankButton from "../../components/ui/BlankButton";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import ProductNavigation from "../../components/Layout/productNavigation";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import Pillbutton from "../../components/ui/pillbutton";
import { useStaticQuery, graphql } from "gatsby";
import Footer from "../../components/Layout/footer";
import SEO from "../../components/seo";

const Aetos100 = () => {
  const query = useStaticQuery(graphql`
    {
      prismicProducts(uid: { eq: "aetos100" }) {
        data {
          seo_title
          seo_description
          cards {
            card_description

            card_overline
            card_image_lg {
              gatsbyImageData
            }
            card_image_ms {
              gatsbyImageData
            }
            card_image_sm {
              gatsbyImageData
            }
            card_image_xl {
              gatsbyImageData
            }
            card_title {
              text
            }
          }
          product_name {
            text
          }
          tagline
          cover_image_sm {
            gatsbyImageData
          }
          cover_image_md {
            gatsbyImageData
          }
          cover_image_lg {
            gatsbyImageData
          }
          cover_image_xl {
            gatsbyImageData
          }
          product_introduction {
            text
          }
          product_gallery {
            color {
              text
            }
            grid_01_sm {
              gatsbyImageData
            }
            grid_01_xl {
              gatsbyImageData
            }
            grid_02_sm {
              gatsbyImageData
            }
            grid_02_xl {
              gatsbyImageData
            }
            grid_03_sm {
              gatsbyImageData
            }
            grid_03_xl {
              gatsbyImageData
            }
            grid_04_sm {
              gatsbyImageData
            }
            grid_04_xl {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const [grid, setGrid] = useState({
    grid01sm: query.prismicProducts.data.product_gallery[0].grid_01_sm,
    grid01xl: query.prismicProducts.data.product_gallery[0].grid_01_xl,
    grid02sm: query.prismicProducts.data.product_gallery[0].grid_02_sm,
    grid02xl: query.prismicProducts.data.product_gallery[0].grid_02_xl,
    grid03sm: query.prismicProducts.data.product_gallery[0].grid_03_sm,
    grid03xl: query.prismicProducts.data.product_gallery[0].grid_03_xl,
    grid04sm: query.prismicProducts.data.product_gallery[0].grid_04_sm,
    grid04xl: query.prismicProducts.data.product_gallery[0].grid_04_xl,
    colorName: query.prismicProducts.data.product_gallery[0].color.text,
  });

  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
    <SEO 
    title = {query.prismicProducts.data.seo_title}
    desc = {query.prismicProducts.data.seo_description}/>
      <ProductLayout>
        <ProductNavigation
          productName="Aetos 100"
          featuresLink="/products/aetos-100/"
          buyLink="/coming-soon/"
          techSpecsLink="/products/aetos-100/specs"
          faqLink="/products/aetos-100/faq"
        />
        <div className="bg-gray-900">
          {/* 
          
          =======================================================
          COVER IMAGE SECTION
          =======================================================
          
          */}
          {/* COVER IMAGE SM */}
          <div className="h-[640px] w-full relative md:hidden">
            {/* Cover Image - Mobile */}
            <GatsbyImage
              image={getImage(query.prismicProducts.data.cover_image_sm)}
              className="w-full h-full absolute"
            />
            <div className="top-12 flex flex-col space-y-2 items-center justify-center absolute w-full">
              <h1 className="text-4xl font-bold text-white text-center">
                {query.prismicProducts.data.product_name.text}
              </h1>
              <p className="text-lg text-gray-400 text-center">
                {query.prismicProducts.data.tagline}
              </p>
            </div>
          </div>
          {/* COVER IMAGE MD */}
          <div className="h-[640px] w-full relative hidden md:block lg:hidden">
            {/* Cover Image - MD */}
            <GatsbyImage
              image={getImage(query.prismicProducts.data.cover_image_md)}
              className="w-full h-full absolute"
            />
            <div className="top-12 flex flex-col space-y-3 items-center justify-center absolute w-full">
              <h1 className="text-5xl font-bold text-white text-center">
                {query.prismicProducts.data.product_name.text}
              </h1>
              <p className="text-lg text-gray-400 text-center">
                {query.prismicProducts.data.tagline}
              </p>
            </div>
          </div>
          {/* COVER IMAGE LG */}
          <div className="h-[640px] w-full relative hidden lg:block xl:hidden">
            {/* Cover Image - LG */}
            <GatsbyImage
              image={getImage(query.prismicProducts.data.cover_image_lg)}
              className="w-full h-full absolute"
            />
            <div className="pt-12 flex flex-col space-y-4 items-center justify-center relative">
              <h1 className="text-6xl font-bold text-white">
                {query.prismicProducts.data.product_name.text}
              </h1>
              <p className="text-xl text-gray-400">
                {query.prismicProducts.data.tagline}
              </p>
            </div>
          </div>
          {/* COVER IMAGE XL */}
          <div className="h-[640px] w-full relative hidden xl:block">
            {/* Cover Image - XL */}
            <GatsbyImage
              image={getImage(query.prismicProducts.data.cover_image_xl)}
              className="w-full h-full absolute"
            />
            <div className="top-12 flex flex-col space-y-4 items-center justify-center absolute w-full">
              <h1 className="text-6xl font-bold text-white text-center">
                {query.prismicProducts.data.product_name.text}
              </h1>
              <p className="text-xl text-gray-400 text-center">
                {query.prismicProducts.data.tagline}
              </p>
            </div>
          </div>
          {/* 
          
          =======================================================
          PRODUCT INTRODUCTION
          =======================================================
          
          */}
          <div className="p-8 md:p-0 md:py-8 lg:py-12 md:max-w-[480px] lg:max-w-screen-sm xl:max-w-screen-md mx-auto">
            {/* Introduction */}
            <p className="text-lg text-gray-300 leading-relaxed md:text-center md:text-2xl md:leading-relaxed xl:text-3xl xl:leading-normal font-light">
              {query.prismicProducts.data.product_introduction.text}
            </p>
          </div>
          {/* 
          
          =======================================================
          PRODUCT GALLERY
          =======================================================
          
          */}
          {/* PRODUCT COLOR GALLERY SM */}
          <div className="grid grid-cols-2 grid-rows-5 gap-2 relative md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg md:mx-auto md:hidden">
            {/* Product Color Gallery */}
            <div className="w-full col-span-2 row-span-2 bg-gray-600 h-[320px] md:h-[410px] lg:h-[492px] xl:h-[656px] relative">
              {/* Grid 01 */}
              <GatsbyImage
                image={getImage(grid.grid01sm)}
                className="w-full h-full absolute md:hidden"
              />
            </div>
            <div className="w-full col-span-1 row-span-2 bg-gray-600 relative">
              {/* Grid 02 */}
              <GatsbyImage
                image={getImage(grid.grid02sm)}
                className="w-full h-full absolute md:hidden"
              />
            </div>
            <div className="w-full col-span-1 row-span-1 bg-gray-600 relative">
              {/* Grid 03 */}
              <GatsbyImage
                image={getImage(grid.grid03sm)}
                className="w-full h-full absolute md:hidden"
              />
            </div>
            <div className="w-full col-span-1 row-span-1 bg-gray-600 relative">
              {" "}
              {/* Grid 04 */}
              <GatsbyImage
                image={getImage(grid.grid04sm)}
                className="w-full h-full absolute md:hidden"
              />
            </div>
            <div className="py-8 col-span-2 row-span-1 place-self-center place-items-center flex flex-col space-y-6 items-center">
              <ul className="flex flex-row space-x-4">
                <li
                  className="h-8 w-8 md:h-12 md:w-12 bg-gray-800 rounded-full active:ring-4 active:ring-blue-200 active:ring-offset-2 shadow"
                  onClick={() =>
                    setGrid(
                      {
                        grid01sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_01_sm,
                        grid01xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_01_xl,
                        grid02sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_02_sm,
                        grid02xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_02_xl,
                        grid03sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_03_sm,
                        grid03xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_03_xl,
                        grid04sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_04_sm,
                        grid04xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_04_xl,
                        colorName:
                          query.prismicProducts.data.product_gallery[0].color
                            .text,
                      },
                      toggleClass()
                    )
                  }
                ></li>
                <li
                  className="h-8 w-8 md:h-12 md:w-12 bg-gray-400 rounded-full active:ring-4 active:ring-blue-200 active:ring-offset-2 shadow"
                  onClick={() =>
                    setGrid(
                      {
                        grid01sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_01_sm,
                        grid01xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_01_xl,
                        grid02sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_02_sm,
                        grid02xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_02_xl,
                        grid03sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_03_sm,
                        grid03xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_03_xl,
                        grid04sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_04_sm,
                        grid04xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_04_xl,
                        colorName:
                          query.prismicProducts.data.product_gallery[1].color
                            .text,
                      },
                      toggleClass()
                    )
                  }
                >
                  {/* Glossy Yellow */}
                </li>
                <li
                  className="h-8 w-8 md:h-12 md:w-12 bg-gray-100 rounded-full active:ring-4 active:ring-blue-200 active:ring-offset-2 shadow"
                  onClick={() =>
                    setGrid(
                      {
                        grid01sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_01_sm,
                        grid01xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_01_xl,
                        grid02sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_02_sm,
                        grid02xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_02_xl,
                        grid03sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_03_sm,
                        grid03xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_03_xl,
                        grid04sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_04_sm,
                        grid04xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_04_xl,
                        colorName:
                          query.prismicProducts.data.product_gallery[2].color
                            .text,
                      },
                      toggleClass()
                    )
                  }
                ></li>
              </ul>
              <p className="text-xl md:text-2xl font-medium text-gray-400 mt-4">
                {grid.colorName}
              </p>
            </div>
          </div>
          {/* PRODUCT COLOR GALLERY MD */}
          <div className="grid-cols-2 grid-rows-5 gap-2 relative md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg md:mx-auto hidden md:grid">
            {/* Product Color Gallery LG*/}
            <div className="w-full col-span-2 row-span-2 bg-gray-600 h-[320px] md:h-[410px] lg:h-[492px] xl:h-[656px] relative">
              {/* Grid 01 */}
              <GatsbyImage
                image={getImage(grid.grid01xl)}
                className="w-full h-full absolute hidden md:block"
              />
            </div>
            <div className="w-full col-span-1 row-span-2 bg-gray-600 relative">
              {/* Grid 02 */}
              <GatsbyImage
                image={getImage(grid.grid02xl)}
                className="w-full h-full absolute hidden md:block"
              />
            </div>
            <div className="w-full col-span-1 row-span-1 bg-gray-600 relative">
              {/* Grid 03 */}
              <GatsbyImage
                image={getImage(grid.grid03xl)}
                className="w-full h-full absolute hidden md:block"
              />
            </div>
            <div className="w-full col-span-1 row-span-1 bg-gray-600 relative">
              {" "}
              {/* Grid 04 */}
              <GatsbyImage
                image={getImage(grid.grid04xl)}
                className="w-full h-full absolute hidden md:block"
              />
            </div>
            <div className="py-8 col-span-2 row-span-1 place-self-center place-items-center flex flex-col space-y-6 items-center">
              <ul className="flex flex-row space-x-4">
                <li
                  className="h-8 w-8 md:h-12 md:w-12 bg-gray-800 rounded-full active:ring-4 active:ring-blue-200 active:ring-offset-2 shadow"
                  onClick={() =>
                    setGrid(
                      {
                        grid01sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_01_sm,
                        grid01xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_01_xl,
                        grid02sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_02_sm,
                        grid02xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_02_xl,
                        grid03sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_03_sm,
                        grid03xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_03_xl,
                        grid04sm:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_04_sm,
                        grid04xl:
                          query.prismicProducts.data.product_gallery[0]
                            .grid_04_xl,
                        colorName:
                          query.prismicProducts.data.product_gallery[0].color
                            .text,
                      },
                      toggleClass()
                    )
                  }
                ></li>
                <li
                  className="h-8 w-8 md:h-12 md:w-12 bg-gray-400 rounded-full active:ring-4 active:ring-blue-200 active:ring-offset-2 shadow"
                  onClick={() =>
                    setGrid(
                      {
                        grid01sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_01_sm,
                        grid01xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_01_xl,
                        grid02sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_02_sm,
                        grid02xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_02_xl,
                        grid03sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_03_sm,
                        grid03xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_03_xl,
                        grid04sm:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_04_sm,
                        grid04xl:
                          query.prismicProducts.data.product_gallery[1]
                            .grid_04_xl,
                        colorName:
                          query.prismicProducts.data.product_gallery[1].color
                            .text,
                      },
                      toggleClass()
                    )
                  }
                >
                  {/* Glossy Yellow */}
                </li>
                <li
                  className="h-8 w-8 md:h-12 md:w-12 bg-gray-100 rounded-full active:ring-4 active:ring-blue-200 active:ring-offset-2 shadow"
                  onClick={() =>
                    setGrid(
                      {
                        grid01sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_01_sm,
                        grid01xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_01_xl,
                        grid02sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_02_sm,
                        grid02xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_02_xl,
                        grid03sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_03_sm,
                        grid03xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_03_xl,
                        grid04sm:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_04_sm,
                        grid04xl:
                          query.prismicProducts.data.product_gallery[2]
                            .grid_04_xl,
                        colorName:
                          query.prismicProducts.data.product_gallery[2].color
                            .text,
                      },
                      toggleClass()
                    )
                  }
                ></li>
              </ul>
              <p className="text-xl md:text-2xl font-medium text-gray-400 mt-4">
                {grid.colorName}
              </p>
            </div>
          </div>
          {/* 
          
          =======================================================
          PRODUCT FEATURES GRID
          =======================================================
          
          */}
          <div className="grid grid-cols-1 gap-2 mx-2 md:mx-auto md:grid-cols-2 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg py-4">
            {/* 
          
          =======================================================
          POWERTRAIN CARD
          =======================================================
          
          */}
            {/* POWERTRAIN CARD SM */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-br from-[#2C5364] to-[#0F2027] rounded-xl relative md:col-span-2 md:hidden">
              {/* Powertrain */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[0].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[0].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[0].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[0].card_description}
                </p>{" "}
              </div>
            </div>
            {/* POWERTRAIN CARD MD */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-br from-[#2C5364] to-[#0F2027] rounded-xl relative md:col-span-2 hidden md:block lg:hidden">
              {/* Powertrain */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[0].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[0].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[0].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[0].card_description}
                </p>{" "}
              </div>
            </div>
            {/* POWERTRAIN CARD LG */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-br from-[#2C5364] to-[#0F2027] rounded-xl relative md:col-span-2 hidden lg:block xl:hidden">
              {/* Powertrain */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[0].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[0].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[0].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[0].card_description}
                </p>{" "}
              </div>
            </div>
            {/* POWERTRAIN CARD XL */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-br from-[#2C5364] to-[#0F2027] rounded-xl relative md:col-span-2 hidden xl:block">
              {/* Powertrain */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[0].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[0].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[0].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[0].card_description}
                </p>{" "}
              </div>
            </div>
            {/* 
          
          =======================================================
          CONTROLLER CARD
          =======================================================
          
          */}
            {/* CONTROLLER CARD SM */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#0065ad] to-[#00061B] rounded-xl relative md:col-span-2 md:hidden">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[1].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute w-[295px] top-6 left-6 md:w-[350px] xl:w-[440px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[1].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[1].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[1].card_description}
                </p>
              </div>
            </div>
            {/* CONTROLLER CARD MD */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#0065ad] to-[#00061B] rounded-xl relative md:col-span-2 hidden md:block lg:hidden">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[3].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute w-[295px] top-6 left-6 md:w-[350px] xl:w-[440px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[1].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[1].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[1].card_description}
                </p>
              </div>
            </div>
            {/* CONTROLLER CARD LG */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#0065ad] to-[#00061B] rounded-xl relative md:col-span-2 hidden lg:block xl:hidden">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[1].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute w-[295px] top-6 left-6 md:w-[350px] xl:w-[440px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[1].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[1].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[1].card_description}
                </p>
              </div>
            </div>
            {/* CONTROLLER CARD XL */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#0065ad] to-[#00061B] rounded-xl relative md:col-span-2 hidden xl:block">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[1].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute w-[295px] top-6 left-6 md:w-[350px] xl:w-[440px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[1].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[1].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[1].card_description}
                </p>
              </div>
            </div>
            {/* 
          
          =======================================================
          BRAKES CARD
          =======================================================
          
          */}
            {/* BRAKES CARD SM */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-br from-[#C04848] to-[#480048] rounded-xl relative md:hidden">
              {/* Disc Brakes */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[2].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute bottom-0">
                <h5 className="text-base md:text-xl text-white xl:text-white font-bold">
                  {query.prismicProducts.data.cards[2].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[2].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[2].card_description}
                </p>
              </div>
            </div>
            {/* BRAKES CARD MD */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-br from-[#C04848] to-[#480048] rounded-xl relative hidden md:block lg:hidden">
              {/* Disc Brakes */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[2].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute bottom-0">
                <h5 className="text-base md:text-xl text-white xl:text-white font-bold">
                  {query.prismicProducts.data.cards[2].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[2].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[2].card_description}
                </p>
              </div>
            </div>
            {/* BRAKES CARD LG */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-br from-[#C04848] to-[#480048] rounded-xl relative hidden lg:block xl:hidden">
              {/* Disc Brakes */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[2].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute bottom-0">
                <h5 className="text-base md:text-xl text-white xl:text-white font-bold">
                  {query.prismicProducts.data.cards[2].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[2].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[2].card_description}
                </p>
              </div>
            </div>
            {/* BRAKES CARD XL */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-br from-[#C04848] to-[#480048] rounded-xl relative hidden xl:block">
              {/* Disc Brakes */}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[2].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute bottom-0">
                <h5 className="text-base md:text-xl text-white xl:text-white font-bold">
                  {query.prismicProducts.data.cards[2].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[2].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[2].card_description}
                </p>
              </div>
            </div>
            {/* 
          
          =======================================================
          WATERPROOFING CARDS
          =======================================================
          
          */}
            {/* WATERPROOF CARD SM */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#0f766e] to-[#2dd4bf] rounded-xl relative md:hidden">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[3].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[3].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-4xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[3].card_title.text}
                </h2>
              </div>
            </div>
            {/* WATERPROOF CARD MD */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#0f766e] to-[#2dd4bf] rounded-xl relative hidden md:block lg:hidden">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[3].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[3].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-4xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[3].card_title.text}
                </h2>
              </div>
            </div>
            {/* WATERPROOF CARD LG */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#0f766e] to-[#2dd4bf] rounded-xl relative hidden lg:block xl:hidden">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[3].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[3].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-4xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[3].card_title.text}
                </h2>
              </div>
            </div>
            {/* WATERPROOF CARD XL */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#0f766e] to-[#2dd4bf] rounded-xl relative hidden xl:block">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[3].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[3].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-4xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[3].card_title.text}
                </h2>
              </div>
            </div>
            {/* 
          
          =======================================================
          ERGONOMIC CARD
          =======================================================
          
          */}
            {/* ERGONOMICS SM */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#ED213A] to-[#93291E] rounded-xl relative md:hidden">
              {/* Ergonomics*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[4].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[4].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[4].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[4].card_description}
                </p>
              </div>
            </div>
            {/* ERGONOMICS MD */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#ED213A] to-[#93291E] rounded-xl relative hidden md:block lg:hidden">
              {/* Ergonomics*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[4].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[4].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[4].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[4].card_description}
                </p>
              </div>
            </div>
            {/* ERGONOMICS LG */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#ED213A] to-[#93291E] rounded-xl relative hidden lg:block xl:hidden">
              {/* Ergonomics*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[4].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[4].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[4].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[4].card_description}
                </p>
              </div>
            </div>
            {/* ERGONOMICS XL */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#ED213A] to-[#93291E] rounded-xl relative hidden xl:block">
              {/* Ergonomics*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[4].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[4].card_overline}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[4].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[4].card_description}
                </p>
              </div>
            </div>
            {/* 
          
          =======================================================
          DASHBOARD CARD
          =======================================================
          
          */}
            {/* DASHBOARD SM */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#7c3aed] to-[#af8bfa] rounded-xl relative md:hidden">
              {/* Dashboard*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[5].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[5].card_overline}{" "}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[5].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[5].card_description}
                </p>
              </div>
            </div>
            {/* DASHBOARD MD */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#7c3aed] to-[#af8bfa] rounded-xl relative hidden md:block lg:hidden">
              {/* Dashboard*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[5].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[5].card_overline}{" "}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[5].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[5].card_description}
                </p>
              </div>
            </div>
            {/* DASHBOARD LG */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#7c3aed] to-[#af8bfa] rounded-xl relative hidden lg:block xl:hidden">
              {/* Dashboard*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[5].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[5].card_overline}{" "}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[5].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[5].card_description}
                </p>
              </div>
            </div>
            {/* DASHBOARD XL */}
            <div className="h-[640px] xl:h-[800px] bg-gradient-to-b from-[#7c3aed] to-[#af8bfa] rounded-xl relative hidden xl:block">
              {/* Dashboard*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[5].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 z-10 absolute top-0">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[5].card_overline}{" "}
                </h5>
                <h2 className="text-3xl xl:text-5xl xl:leading-normal text-white font-bold leading-normal">
                  {query.prismicProducts.data.cards[5].card_title.text}
                </h2>
                <p className="text-base xl:text-xl xl:leading-relaxed text-white font-regular leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[5].card_description}
                </p>
              </div>
            </div>
            {/* 
          
          =======================================================
          CRAFTSMANSHIP CARD
          =======================================================
          
          */}
            {/* CRAFTSMANSHIP SM */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#42275a] to-[#734b6d] rounded-xl relative md:col-span-2 md:hidden">
              {/* Craftsmanship*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[6].card_image_sm
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[6].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[6].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[6].card_description}
                </p>{" "}
              </div>
            </div>
            {/* CRAFTSMANSHIP MD */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#42275a] to-[#734b6d] rounded-xl relative md:col-span-2 hidden md:block lg:hidden">
              {/* Craftsmanship*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[6].card_image_ms
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[6].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[6].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[6].card_description}
                </p>{" "}
              </div>
            </div>
            {/* CRAFTSMANSHIP LG */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#42275a] to-[#734b6d] rounded-xl relative md:col-span-2 hidden lg:block xl:hidden">
              {/* Craftsmanship*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[6].card_image_lg
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[6].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[6].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[6].card_description}
                </p>{" "}
              </div>
            </div>
            {/* CRAFTSMANSHIP XL */}
            <div className="h-[640px] md:h-[480px] xl:h-[640px] bg-gradient-to-b from-[#42275a] to-[#734b6d] rounded-xl relative md:col-span-2 hidden xl:block">
              {/* Craftsmanship*/}
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[6].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 z-10 absolute max-w-[295px] top-6 left-6 md:max-w-[350px] xl:max-w-[467px] xl:top-10 xl:left-10">
                <h5 className="text-base md:text-xl text-white font-bold">
                  {query.prismicProducts.data.cards[6].card_overline}
                </h5>
                <h2 className="text-3xl md:text-4xl xl:text-5xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  {query.prismicProducts.data.cards[6].card_title.text}
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  {query.prismicProducts.data.cards[6].card_description}
                </p>{" "}
              </div>
            </div>
            {/* 
          
          =======================================================
          TEST RIDE & DEALER LOCATOR
          =======================================================
          
          */}
            <div className=" h-[240px] md:h-[385px] col-span-1 bg-gray-600 p-6 rounded-xl flex flex-col justify-between">
              {/* Test Ride Card */}
              <div className="flex flex-col space-y-2">
                <h5 className="text-base md:text-xl text-white font-bold">
                  TEST RIDES
                </h5>
                <h2 className="text-3xl md:text-4xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  Test Ride Kollegio
                </h2>
                <p className="text-base md:text-xl text-white font-regular leading-relaxed md:leading-relaxed md:pr-6">
                  Fill out this form to register for Test Ride in your Nearest
                  Showroom.
                </p>
              </div>
              <div>
                <Pillbutton name="Register Now" />
              </div>
            </div>
            <div className=" h-[240px] md:h-[385px] col-span-1 bg-gray-600 p-6 rounded-xl flex flex-col justify-between">
              {/* Dealers Locator Card */}
              <div className="flex flex-col space-y-2">
                <h5 className="text-base md:text-xl text-white font-bold">
                  DEALERS
                </h5>
                <h2 className="text-3xl md:text-4xl text-white font-bold leading-normal md:leading-normal xl:leading-normal">
                  Locate your Nearest Dealership
                </h2>
              </div>
              <div>
                <Pillbutton name="Register Now" />
              </div>
            </div>
            {/* 
          
          =======================================================
          BOOK NOW CARD
          =======================================================
          
          */}
            <div className="col-span-1 md:col-span-2 h-[400px] md:h-[480px] xl:h-[640px] bg-blue-500 relative rounded-xl">
              <GatsbyImage
                image={getImage(
                  query.prismicProducts.data.cards[7].card_image_xl
                )}
                className="w-full h-full absolute"
              />
              <div className="flex flex-col space-y-2 p-6 md:p-10">
                <h5 className="text-base md:text-xl text-white font-bold md:text-center">
                  {query.prismicProducts.data.cards[7].card_overline}
                </h5>
                <h2 className="text-2xl md:text-4xl text-white font-semibold leading-normal md:leading-normal xl:leading-normal pr-16 md:text-center md:px-24 lg:px-36 xl:px-72">
                  {query.prismicProducts.data.cards[7].card_title.text}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </ProductLayout>
      <Footer />
    </>
  );
};

export default Aetos100;
